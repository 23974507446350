import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store.js';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import VueIziToast from 'vue-izitoast';
import VueTheMask from 'vue-the-mask';
import Fragment from 'vue-fragment';

/* Importa o Hightchart's e o modulo exporting */
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);

import './plugins/base';
import './plugins/vee-validate';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.browser.print/dist/leaflet.browser.print.min';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';
import 'leaflet.wms';
import 'leaflet-control-geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-loading';
import 'leaflet-loading/src/Control.Loading.css';
import 'spin.js';
import 'leaflet-spin';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'izitoast/dist/css/iziToast.min.css';
import '@/filters.js';

Vue.config.productionTip = false;

Vue.use(VueIziToast);

Vue.use(VueTheMask);

Vue.use(HighchartsVue);
Vue.use(Fragment.Plugin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
