import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '@/i18n';
import '@/sass/overrides.sass';

Vue.use(Vuetify);

const theme = {
  primary: '#003A63',
  secondary: '#2F2E79',
  accent: '#6D6EA8',

  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  },
  icons: {
    values: {
      //
    }
  }
});
