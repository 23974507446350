import APIService from './APIService';

export default {
  login(login, password, companyId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('/auth/login', {
          login,
          password,
          companyId
        })
        .then((response) => {
          const { access_token: token } = response.data;
          this.setLocalToken(token);
          resolve(true);
        })
        .catch((error) => reject(error));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('/auth/logout')
        .then(() => {
          this.setLocalToken(null);
          resolve(true);
        })
        .catch((error) => reject(error));
    });
  },

  setLocalToken(token) {
    sessionStorage.setItem('app-auth-token', token);
    return true;
  },

  getLocalToken() {
    return sessionStorage.getItem('app-auth-token');
  },

  getLoggedUser() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('/auth/me')
        .then((response) => {
          const user = response.data;
          resolve(user);
        })
        .catch((error) => reject(error));
    });
  },

  getUserInfo() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('/auth/getUserInfo')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },

  getUserInfoById(userId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/auth/getUserInfoById/${userId}`)
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },

  getUserPermissions()
  {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(`/auth/getUserPermissions`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  async userIsAdmin() {
    let isAdmin = false;
    try {
      const userInfo = await this.getUserInfo();
      isAdmin = userInfo.admin;
    } catch (e) {
      console.log(e);
    }
    return isAdmin;
  },

  getAmbienteAplicacao() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/auth/get-ambiente-aplicacao')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },

  getTipoAutenticacao() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/auth/get-tipo-autenticacao')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post(`/password/request-reset`, {
          email,
          redirectUrl: `${window.location.origin}/#/redefinir-senha`
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },

  resetPassword(props) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post(`/password/reset`, props)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  }
};
