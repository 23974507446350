import Vue from 'vue';
import Router from 'vue-router';

import AuthService from '@/services/AuthService';
import routes from '@/router/routes';
import store from '@/store';
import appRoutes from '@/store/modules/routes';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

const userIsAuthenticated = (store) => {
  const { getUserId, getCompanyId } = store.getters;
  const token = AuthService.getLocalToken();
  return getUserId && getCompanyId && token;
};

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = userIsAuthenticated(store);
  
  const routes = store.getters.getRoutesFromCompany;
  const routesReleased = [
    appRoutes.state.appRoutes['LOGIN'],
    appRoutes.state.appRoutes['HOME'],
    appRoutes.state.appRoutes['VIZUALIZAR_PERFIL'],
    appRoutes.state.appRoutes['LOGOUT'],
    appRoutes.state.appRoutes['REDEFINIR_SENHA'],
    appRoutes.state.appRoutes['ESQUECEU_SENHA'],
  ];

  if (requiresAuth && !isAuthenticated) {
    return next({ name: appRoutes.state.appRoutes['LOGIN'] });
  }

  if (!store.getters.getUserIsAdmin && !routesReleased.includes(to.name)) {

    if (!routes.some(url => url.includes(to.name))){
      console.log('O usuário não possui acesso a rota selecionada.');
      return next({ name: appRoutes.state.appRoutes['LOGIN'] });
    }
  }

  return next();
};
router.beforeEach(waitForStorageToBeReady);

export default router;
