import store from '@/store';

const initialState = {
  initialTitle: 'T-BDGD'
};

const state = {
  appTitle: initialState
};

const getters = {
  getAppInitialTitle: (state) => state.appTitle.initialTitle, 
  getAppTitle: (state, getters) => {
    const initialTitle = getters.getAppInitialTitle;
    let appTitle = initialTitle;
    const { 
      getCompanyAcronym, 
      getUserSelectedCompanyBdgdVersionTxt 
    } = store.getters;
    if (getCompanyAcronym) {
      appTitle = `${getCompanyAcronym} :: ${appTitle}`;
    }
    if (getUserSelectedCompanyBdgdVersionTxt) {
      appTitle = `${getCompanyAcronym} :: ${getUserSelectedCompanyBdgdVersionTxt} :: ${initialTitle}`;
    }
    return appTitle;
  }
};

export default {
  state,
  getters
};
